<template>
  <App title="Orders Detail" @click-left="onClickLeft" left-arrow>
    <div class="order-status">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <h5 class="order-status-text">{{ orderInfo["_status"] }}</h5>
        <Button style="color: #fff; background-color: #DA3738; border: 0"
        @click="sendShare"
          >Send Receipt</Button
        >
      </div>
    </div>
    <div class="card">
      <cuField label="Vehicle" class="card-title" input-align="right" readonly>
      </cuField>
      <cuField
        v-for="(item, index) in carInfoFields"
        :key="index"
        :label="item.label"
        :value="orderInfo[item.key]"
        input-align="right"
        readonly
      />
    </div>
    <div class="card">
      <cuField label="Order" class="card-title" readonly />
      <Slide slideUpText="Collapse" slideDownText="展开">
        <cuField
          v-for="(item, index) in orderInfoFields.slice(0, 6)"
          :key="index"
          :label="item.label"
          :value="orderInfo[item.key]"
          input-align="right"
          readonly
        >
          <template v-if="item.key === 'orderNum'" slot="input">
            {{ orderInfo[item.key] }}
            <a
              v-clipboard:copy="orderInfo[item.key]"
              v-clipboard:success="onCopy"
              href="javascript:;"
              class="link-text"
              style="color:#DA3738"
              >Copy</a
            >
          </template>
        </cuField>
        <template slot="extra">
          <template v-for="(item, index) in orderInfoFields.slice(6)">
            <van-field
              v-if="item.key === '_refundReason'"
              :key="index"
              :label="item.label"
              :value="orderInfo[item.key]"
              rows="1"
              autosize
              input-align="right"
              type="textarea"
              readonly
            />
            <cuField
              v-else
              :key="index"
              :label="item.label"
              :value="orderInfo[item.key]"
              input-align="right"
              readonly
            />
          </template>
        </template>
      </Slide>
    </div>
    <div class="card">
      <cuField
        label="Buyer"
        class="card-title"
        input-align="right"
        readonly
      >
      </cuField>
      <template v-for="(item, index) in buyInfoFields">
        <cuField
          v-if="!item.visible || item.visible()"
          :key="index"
          :label="item.label"
          :value="orderInfo[item.key]"
          input-align="right"
          readonly
        >
          <template slot="input">
            {{ orderInfo[item.key] }}
          </template>
        </cuField>
      </template>
    </div>

   
  </App>
</template>
<script>
import dayjs from "dayjs";
import { Button, Dialog, Toast, Field } from "vant";
import cuField from "_c/dynamicForm/field";
import Slide from "_c/slide";
import iconEdit from "../../assets/images/icon/icon_edit@2x.png";
import * as orderApi from "@/api/order";
import {
  channelMap,
  deliveryStatusAttr,
  orderStatusAttr,
  orderContractStatusMap,
  cardTypeMap,
} from "@/utils/config";
export default {
  name: "orderDetail",
  components: {
    cuField,
    Slide,
    Button,
    [Field.name]: Field,
  },
  data() {
    return {
      iconEdit,
      orderNum: "",
      orderInfo: {},
      orderInfoFields: [
        { label: "Order No", key: "orderNum" },
        { label: "Created Time", key: "_createTime" },
        // { label: "订单状态", key: "_status" },
        { label: "Deposit Payment Time", key: "_orderPaymentTime" },
        // { label: "意向金支付时间", key: "_paymentTime" },
        // { label: "转大定时间", key: "_orderDate" },
        // { label: "订单渠道", key: "_channel" },
        // { label: "订单类型", key: "_orderType" },
        // { label: "出行顾问", key: "consultantName" },
        // { label: "销售门店", key: "saleDlr" },
        // { label: "邀请人", key: "invitationUid" },
        // { label: "邀请码", key: "invitationCode" },
        // { label: "合同状态", key: "_orderContractStatus" },
        // { label: "是否冻结", key: "_frozenStatus" },
        // { label: "金融方案", key: "_loanFlag" },
        // { label: "上牌资质", key: "_licenseFlag" },
        // { label: "订单分类标识", key: "_orderClass" },
      ],
      buyInfoFields: Object.freeze([
        { label: "Buyer Name", key: "ownerName" },
        { label: "Phone Number", key: "ownerPhone" },
        { label: "City of Registration", key: "_provinceAndCity" },
        { label: "City of Registration", key: "_purchaseType" },
        {
          label: "ID Type",
          key: "_ownerCardType",
          visible: () => this.orderInfo.ownerType === "00",
        },
        {
          label: "ID No",
          key: "ownerCardNo",
          visible: () => this.orderInfo.ownerType === "00",
        },
        {
          label: "Company Name",
          key: "enterpriseName",
          visible: () => this.orderInfo.ownerType === "01",
        },
        {
          label: "Business Registration Number",
          key: "enterpriseCode",
          visible: () => this.orderInfo.ownerType === "01",
        },
        {
          label: "Company Address",
          key: "enterpriseAddr",
          visible: () => this.orderInfo.ownerType === "01",
        },
      ]),
      useInfoFields: Object.freeze([
        { label: "用车人姓名", key: "mainUserName" },
        { label: "联系方式", key: "mainUserPhone" },
        {
          label: "证件号码",
          key: "mainUserCardNo",
          visible: () => this.orderInfo.ownerType === "00",
        },
      ]),
      counselorInfoFields: Object.freeze([
        { label: "出行顾问", key: "consultantName" },
        { label: "联系方式", key: "consultantMobile" },
      ]),
      orderConfirmFields: Object.freeze([
        { label: "确认时间", key: "_saleConfirmDate" },
      ]),
      deliveryInfoFields: Object.freeze([
        { label: "交付中心", key: "dcDlr" },
        { label: "交付体验顾问", key: "deliverEmpName" },
        // { label: '联系电话', key: 'deliverEmpPhone' },
        { label: "预计交付日期", key: "_preSaleConfirmDate" },
        { label: "实际交付日期", key: "_deliveryDate" },
        { label: "交付状态", key: "_orderStatus" },
      ]),
      carInfoFields: [
        { label: "Model", key: "carSeries" },
        { label: "Specification", key: "carType" },
        { label: "Powertrain", key: "vehicleCode" },
        { label: "Colour", key: "carColor" },
      ],
      btnControl: {
        contract: false,
        unContract: false,
        handOff: false,
        pay: false,
        refund: false,
        largeSetTurn: false,
        supplyCar: false,
        banEdit: false,
      },
      showFooter: true,
      source: "", // 到当前页面的来源
    };
  },
  computed: {
    featurePrice() {
      const featureList = this.orderInfo.featureList || [];
      const sum = (prev, cur) =>
        cur.relationShip === "S" ? prev : prev + cur.price * 1;
      return featureList.reduce(sum, 0);
    },
  },
  methods: {
    sendShare() {
      orderApi.leadsPriceOpp({
        type: 1,
        // leadsId:"1405761059545407489",
        shareData: JSON.stringify({
          _createTime: this.orderInfo._createTime,
          orderNum: this.orderInfo.orderNum,
          ownerName: this.orderInfo.ownerName,
          ownerPhone: this.orderInfo.ownerPhone,
          _provinceAndCity: this.orderInfo._provinceAndCity,
          ownerType: this.orderInfo.ownerType,
          _ownerCardType: this.orderInfo._ownerCardType,
          ownerCardNo: this.orderInfo.ownerCardNo,
          enterpriseName: this.orderInfo.enterpriseName,
          enterpriseCode: this.orderInfo.enterpriseCode,
          enterpriseAddr: this.orderInfo.enterpriseAddr,
          carSeries: this.orderInfo.carSeries,
          carType: this.orderInfo.carType,
          vehicleCode: this.orderInfo.vehicleCode,
          carColor: this.orderInfo.carColor,
          depositAmount: this.orderInfo.depositAmount
        })
      }).then((res) => {
        console.log(res.data, '-----');
        
        this.$router.push({
          path: "/DepositReceipt",
          query: {
            id: res.data.id
          }
        })
      })
    },
    // 返回按钮点击
    onClickLeft() {
      if (this.source) {
        // 如果有来源就说明是从创建过来的就返回到客户详情，否则就正常关闭当前页面
        this.$router.push({ path: "/customerInfo" });
      } else {
        this.$router.go(-1);
      }
    },
    init() {
      this.source = this.$route.query ? this.$route.query.source : "";
      const toast = Toast.loading({
        message: "Loading...",
        forbidClick: true,
        loadingType: "spinner",
      });
      this.carInfoFields = [
      { label: "Model", key: "carSeries" },
        { label: "Specification", key: "carType" },
        { label: "Powertrain", key: "vehicleCode" },
        { label: "Colour", key: "carColor" },
      ];
      this.getOrderDetail(this.orderNum).finally(() => {
        toast.clear();
      });
    },
    // 获取订单详情
    getOrderDetail(orderNo) {
      const params = {
        orderNum: orderNo,
        channel: this.$storage.get("channel"),
        _t: dayjs().valueOf(),
      };
      return orderApi.getDetail(params).then((res) => {
        if (res.success) {
          const data = res.data;
          this.sourceData = data;
          if (data.assignStatus !== "0" && this.carInfoFields.length === 4) {
            // this.carInfoFields.push(
            //   { label: "VIN码", key: "vin" },
            //   { label: "配车时间", key: "_assignDate" }
            // );
          }
          if (data.status === "03" || data.status === "05") {
            this.orderInfoFields.push({
              label: "退款原因",
              key: "_refundReason",
            });
          }
          this.orderInfo = Object.assign({}, data, {
            _createTime: dayjs(+data.createdTime * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            _orderPaymentTime:
              data.orderPaymentTime &&
              dayjs(+data.orderPaymentTime * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            _paymentTime:
              data.paymentTime &&
              dayjs(+data.paymentTime * 1000).format("YYYY-MM-DD HH:mm:ss"),
            _orderDate:
              data.orderDate &&
              dayjs(+data.orderDate * 1000).format("YYYY-MM-DD HH:mm:ss"),
            _preSaleConfirmDate:
              data.preSaleConfirmDate &&
              dayjs(+data.preSaleConfirmDate * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            _deliveryDate:
              data.deliveryDate &&
              dayjs(+data.deliveryDate * 1000).format("YYYY-MM-DD HH:mm:ss"),
            _saleConfirmDate:
              data.saleConfirmDate &&
              dayjs(+data.saleConfirmDate * 1000).format("YYYY-MM-DD HH:mm:ss"),
            _assignDate:
              data.assignDate &&
              dayjs(+data.assignDate * 1000).format("YYYY-MM-DD HH:mm:ss"),
            _channel: channelMap[data.channel],
            _orderStatus: deliveryStatusAttr[data.orderStatus],
            _status: orderStatusAttr[data.status],
            _orderContractStatus:
              orderContractStatusMap[data.orderContractStatus],
            _purchaseType:
              data.purchaseType === "00"
                ? "个人"
                : data.purchaseType === "01"
                ? "企业"
                : "",
            _frozenStatus: data.frozenStatus === "1" ? "已冻结" : "未冻结",
            _loanFlag:
              data.loanFlag === "1"
                ? "是"
                : data.orderType === "2"
                ? "否"
                : "待定",
            _licenseFlag:
              data.licenseFlag === "1"
                ? "正常"
                : data.orderType === "2"
                ? "暂无"
                : "风险",
            _orderClass:
              data.orderClass === "2"
                ? "DR"
                : data.orderClass === "3"
                ? "大定"
                : "DR2C",
            _orderType:
              data.orderType === "1"
                ? "客户订单"
                : data.orderType === "2"
                ? "到店订单"
                : "大客户订单",
            _provinceAndCity: data.provinceName + "/" + data.cityName,
            _ownerCardType: cardTypeMap[data.ownerCardType],
            _refundReason:
              data.refundData.length > 0 ? data.refundData[0].refundReason : "",
          });
          this.operationsConfig();
        }
      });
    },
    // 操作按钮配置
    operationsConfig() {
      for (const key in this.btnControl) {
        this.btnControl[key] = false;
      }
      if (this.orderInfo.status === "20" || this.orderInfo.status === "21") {
        // 「定金已支付待确认」「订单已锁配」
        this.btnControl.handOff = true;
        this.btnControl.supplyCar = true;

        this.btnControl.contract = this.orderInfo.orderContractStatus !== "1";
        this.btnControl.unContract = this.orderInfo.orderContractStatus === "1";
      }
      if (this.orderInfo.status === "00" || this.orderInfo.status === "10") {
        // 「意向金未支付」「大定未支付」
        this.btnControl.pay = true;
      }
      if (this.orderInfo.orderClass === "2" && this.orderInfo.status === "02") {
        // 「DR订单」「意向金已支付」
        this.btnControl.refund = true;
        this.btnControl.largeSetTurn = true;
      }
      if (!["00", "02", "10", "20", "21"].includes(this.orderInfo.status)) {
        // 意向金未支付，意向金已支付，定金未支付，定金已付待确认，订单已锁配
        this.btnControl.banEdit = true;
      }
    },
    // 编辑车主
    buyInfoEditPages() {
      this.$router.push({
        path: "/buyInfoEdit",
        query: {
          orderNum: this.orderInfo.orderNum,
          uid: this.orderInfo.uid,
          superId: this.orderInfo.superId,
          channel: this.orderInfo.channel,
          purchaseType: this.orderInfo.purchaseType,
          ownerName: this.orderInfo.ownerName,
          ownerPhone: this.orderInfo.ownerPhone,
          ownerCardType: this.orderInfo.ownerCardType,
          ownerCardNo: this.orderInfo.ownerCardNo,
          province: this.orderInfo.province,
          city: this.orderInfo.city,
          enterpriseName: this.orderInfo.enterpriseName,
          enterpriseCode: this.orderInfo.enterpriseCode,
          enterpriseAddr: this.orderInfo.enterpriseAddr,
        },
      });
    },
    // 编辑主用车人
    carOwnerEditPages() {
      this.$router.push({
        path: "/carOwnerEdit",
        query: {
          orderNum: this.orderInfo.orderNum,
          channel: this.orderInfo.channel,
          ownerName: this.orderInfo.ownerName,
          ownerPhone: this.orderInfo.ownerPhone,
          ownerCardNo: this.orderInfo.ownerCardNo,
          mainUserName: this.orderInfo.mainUserName,
          mainUserPhone: this.orderInfo.mainUserPhone,
          mainUserCardNo: this.orderInfo.mainUserCardNo,
          purchaseType: this.orderInfo.purchaseType,
        },
      });
    },
    // 配车
    configCarPages() {
      this.$router.push({
        path: "/configCar",
        query: {
          orderNum: this.orderInfo.orderNum,
          carSeries: this.orderInfo.carSeries,
          carType: this.orderInfo.carType,
          carColor: this.orderInfo.carColor,
          carColorCode: this.orderInfo.carColorCode,
          dcDlrId: this.orderInfo.dcDlrId,
          consultantId: this.orderInfo.consultantId,
          vehicleCode: this.orderInfo.vehicleCode,
        },
      });
    },
    // 修改配置
    modifyConfigPages() {
      this.$router.push({
        path: "/modifyConfig",
        query: {
          orderNum: this.orderInfo.orderNum,
          channel: this.orderInfo.channel,
          uid: this.orderInfo.uid,
          superId: this.orderInfo.superId,
          consultantId: this.orderInfo.consultantId,
          consultantName: this.orderInfo.consultantName,
          carTypeCode: this.orderInfo.carTypeCode,
          carSeriesCode: this.orderInfo.carSeries,
          featureList: this.orderInfo.featureList,
        },
      });
    },
    // 支付 - 收银台
    checkstandPages() {
      const {
        orderClass,
        status,
        depositAmount,
        intentionAmount,
        receivedAmount,
      } = this.orderInfo;

      let type = 0;
      if (+orderClass === 3) type = 0;
      if (+orderClass === 2) {
        if (status === "00") type = 1;
        if (status === "02") type = 2;
      }

      this.$router.push({
        path: "/checkstand",
        query: {
          type,
          orderNo: this.orderInfo.orderNum,
          amount:
            type === 0
              ? depositAmount
              : type === 1
              ? intentionAmount
              : (+depositAmount - +receivedAmount).toFixed(2),
          subject: this.orderInfo.carSeries,
          channel: this.$storage.get("channel"),
          pageClose: true,
        },
        winOpts: {
          reload: true,
        },
      });
    },
    // 申请退款
    refoundPages() {
      this.$router.push({
        path: "/refound",
        query: this.orderInfo,
      });
    },
    // 订单总计
    orderTotalPages() {
      this.$router.push({
        path: "/orderTotal",
        query: {
          price: this.orderInfo.price,
          acutalPrice: this.orderInfo.acutalPrice,
          invoicePrice: this.orderInfo.invoicePrice,
          receivedAmount: this.orderInfo.receivedAmount,
          gatheringAmount: this.orderInfo.gatheringAmount,
          depositAmount: this.orderInfo.depositAmount,
          intentionAmount: this.orderInfo.intentionAmount,
          discountUniteTotal: this.orderInfo.discountUniteTotal,
          featurePrice: this.featurePrice.toFixed(2),
        },
      });
    },
    // 价格明细
    priceDetailPages() {
      this.$router.push({
        path: "/priceDetail",
        query: {
          featureList: this.orderInfo.featureList,
          discountUniteTotal: this.orderInfo.discountUniteTotal,
          discountSpecialTotal: this.orderInfo.discountSpecialTotal,
        },
      });
    },
    // 勘测单
    surveyOrderPages() {
      this.$router.push({
        path: "/surveyOrder",
        query: {
          orderNum: this.orderInfo.orderNum,
          uid: this.orderInfo.uid,
        },
      });
    },
    // 金融单
    financialOrderPages() {
      this.$router.push({
        path: "/financialOrder",
        query: {
          orderNum: this.orderInfo.orderNum,
          status: this.orderInfo.status,
        },
      });
    },
    // 权益
    benefitPages() {
      this.$router.push({
        path: "/benefit",
        query: {
          orderNum: this.orderInfo.orderNum,
          benefitData: this.orderInfo.benefitData,
        },
      });
    },
    // 资金单
    moneyOrderPages() {
      this.$router.push({
        path: "/moneyOrder",
        query: {
          orderNum: this.orderInfo.orderNum,
          payList: this.orderInfo.payList,
        },
      });
    },
    // 合同签署
    contractPages() {
      this.$router.push({
        path: "/contract",
        query: {
          orderNum: this.orderInfo.orderNum,
          consultantId: this.orderInfo.consultantId,
          consultantName: this.orderInfo.consultantName,
        },
      });
    },
    // 合同撤回
    contractWithdraw() {
      const params = {
        channel: this.$storage.get("channel"),
        orderNum: this.orderInfo.orderNum,
        contractCode: this.orderInfo.contractList[0].contractCode,
        consultantId: this.orderInfo.consultantId,
        consultantName: this.orderInfo.consultantName,
      };
      orderApi.contractWithdraw(params).then((res) => {
        if (res.success) {
          Toast("合同撤回成功");
          this.init();
        } else {
          Toast(res.msg || "撤回失败!");
        }
      });
    },
    // 合同预览
    previewContract(url) {
      this.api.download(
        {
          url: url,
          report: true,
          cache: true,
          allowResume: true,
        },
        (ret, err) => {
          if (ret.state == 1) {
            const docReader = this.api.require("docReader");
            docReader.open(
              { path: ret.savePath.replace(" ", "") },
              (ret, err) => {
                if (ret.status) {
                  console.log(ret);
                } else {
                  console.log(err);
                }
              }
            );
          } else {
            console.log(err);
          }
        }
      );
    },
    // hand-over
    handOverPages() {
      this.$router.push({
        path: "/handOver",
        query: {
          orderNum: this.orderInfo.orderNum,
          saleDlrId: this.orderInfo.saleDlrId,
          consultantId: this.orderInfo.consultantId,
          consultantName: this.orderInfo.consultantName,
          vin: this.orderInfo.vin,
        },
      });
    },
    // 转大定
    largeSet() {
      const diffPrice =
        +this.orderInfo.depositAmount - +this.orderInfo.receivedAmount;
      console.log(diffPrice);
      if (diffPrice <= 0) {
        Dialog.confirm({
          title: "转大定确认",
          message: "是否确认转大定？",
          // beforeClose: this.sureLargeSet
        })
          .then(() => {
            const params = {
              ...this.sourceData,
              provinceId: this.sourceData.province,
              cityId: this.sourceData.city,
              diffPrice: (
                +this.sourceData.depositAmount -
                +this.sourceData.intentionAmount
              ).toFixed(2),
              type: 2,
            };
            orderApi
              .transtoorder(params)
              .then((res) => {
                if (res.success) {
                  Toast("提交成功!");
                  this.getOrderDetail(this.sourceData.orderNum);
                } else {
                  Toast(res.msg || "提交失败!");
                }
              })
              .finally(() => {
                Dialog.close();
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Dialog.confirm({
          title: "转大定确认",
          message: `当前订单需支付差价进行转大定，是否确认支付？\n差价：￥ ${diffPrice.toFixed(
            2
          )}`,
          confirmButtonText: "支付",
        })
          .then(() => {
            this.checkstandPages();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    onCopy() {
      Toast("复制成功!");
    },
    observeFooter() {
      this.$nextTick(() => {
        const observer = new MutationObserver(() => {
          this.showFooter = this.$refs.footer.children.length > 0;
        });
        observer.observe(this.$refs.footer, {
          subtree: true,
          childList: true,
        });
        this.showFooter = this.$refs.footer.children.length > 0;
      });
    },
  },
  mounted() {
    const pageParam = this.$route.query;
    this.orderNum = pageParam.orderNum;
    this.observeFooter();
    this.init();
  },
};
</script>
<style lang="less" scoped>
.order-status {
  height: 174px;
  padding: 33px 24px;
  box-sizing: border-box;
  background-color: #f5f6ff;

  .order-status-text {
    line-height: 45px;
    font-size: 32px;
    font-weight: 800;
    color: #333;
  }

  .order-status-info {
    padding-top: 8px;

    .tag {
      display: inline-block;
      margin-right: 10px;
      padding: 0 24px;
      height: 56px;
      line-height: 56px;
      border-radius: 8px;
      font-size: 28px;
      color: #0f0f13;
      background-color: #ebeef0;
    }

    .tips {
      padding: 0 24px;
      height: 49px;
      line-height: 49px;
      border-radius: 8px;
      font-size: 24px;
      color: #c8831d;
      background-color: #fde3ba;
    }
  }
}

.card {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 72px;
  }

  .card-title {
    /deep/ .van-field__label {
      font-size: 0.373333rem;
      font-weight: bold;
      color: #000;
      width: 3rem;
    }

    .icon-edit {
      width: 48px;
      height: 48px;
    }
  }

  /deep/ .slide-operator {
    height: 1.146667rem;
    line-height: 1.146667rem;
    background-color: #fff;
  }

  .link-text {
    margin-left: 16px;
  }

  button {
    margin-left: 10px;
    padding: 0 24px;
    height: 53px;
    line-height: 53px;
    border: 2px solid #999;
    border-radius: 149px;
    font-size: 26px;
    color: #999;
    background-color: transparent;
  }

  /deep/ .slide .van-cell__title {
    width: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  height: 182px;
  line-height: 98px;
  box-sizing: border-box;
  font-size: 32px;
  background-color: #2b2b31;

  button {
    padding: 0 24px;
    height: 56px;
    line-height: 1;
    border-radius: 8px;
    font-size: 28px;
  }

  button:nth-of-type(1) {
    color: #DA3738;
    border: 2px solid #DA3738;
    background-color: transparent;
  }

  button:nth-of-type(2) {
    margin-left: 16px;
    color: #fff;
    background-color: #DA3738;
    border: 0;
  }
}

.contract {
  /deep/ .van-field__control {
    flex-wrap: wrap;
  }
  .contract-img {
    width: 2.8rem;
    height: 2.8rem;
    margin: 0 15px 15px 0;
    border: 1px #ccc dashed;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>